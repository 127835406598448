
<template>
  <v-card>
    <v-card-title>
      Central User Management
      <v-spacer></v-spacer>
      <v-flex xs12 md6>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-flex>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="$router.push({ path: `user/${item._id}/edit` })">edit</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceAdmin.js";

const service = new RestResource();
export default {
  data() {
    return {
      search: null,
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Country Code", value: "countryCode", sortable: true },
        { text: "Mobile Number", value: "mobileNumber", sortable: true },
        { text: "Actions", value: "actions", sortable: false }
      ],
      valid: true,
      users: [],
      editedItem: {}
    };
  },
  mounted() {
    this.loadUsers();
  },

  methods: {
    loadUsers() {
      this.$setLoader();

      // TODO: Take care of pagination
      service.fetchUsers().then(r => {
        this.users = r.data;
        this.$disableLoader();
      });
    },
  }
};
</script>

<style scoped>
#bt1 {
  float: right;
}
</style>